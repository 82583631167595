<template>
  <div class="container">
    <el-space style="width: 100%" direction="vertical" alignment="stretch">
      <div class="title">二维码列表</div>
      <el-table :data="tableData" style="width: 100%">
        <el-table-column prop="checkpoint" label="卡点ID" width="300" />
        <el-table-column prop="name" label="点位二维码">
          <template #default="scope">
            <img class="qrcode" :src="scope.row.url" />
          </template>
        </el-table-column>
      </el-table>
      <div class="title">生成景区点位二维码</div>
      <el-input
        :rows="12"
        v-model="content"
        class="textarea"
        type="textarea"
        placeholder="多个点位用换行分隔哈~"
      />
      <el-button type="primary" @click="handleGenerateImage">开始生成</el-button>
      <el-divider direction="horizontal" border-style="dashed" />
    </el-space>
  </div>
</template>

<script>
  import $fetch from '@/utils/fetch.js';
  export default {
    name: "generateCode",
    data() {
      return {
        auth: "sid-a7e94e0159592397763aca39c4186b5b",
        content: "",
        tableData: [],
      }
    },
    methods: {
      /** 生成二维码 */
      async handleGenerateImage() {
        let _ = this;
        let { content } = _;
        _.tableData = [];
        if(!content.length) return alert('请输入内容');
        content = content.split('\n');
        for(let checkpoint of content) {
          await _.handleShortenLink(checkpoint.trim());
        }
      },
      /** 获取景区打卡点二维码 */
      async handleGetWechatCode(checkpoint) {
        const _ = this;
        $fetch
          .post("GetWeixinQRCode", {
            page: 'packages/scenic-area/challenge/pages/index/index',
            scene: checkpoint,
            width: 80,
            is_hyaline: true,
          })
          .then((code) => {
            _.tableData.push({ url: "data:image/jpeg;base64, " + code.image,  checkpoint });
          });
      },
      /** 多参数缩短 */ 
      async handleShortenLink(checkpoint) {
        const { auth } = this;
        const { result }  = await $fetch.fetch('xx.cumen.equip.v1.EquipService/ShortenString',
          { action: 1, val: `?checkpoint=${ checkpoint }`, useDb: true },
          { Authorization: auth }
        );
        this.handleGetWechatCode(result);
      },
    }
  }
</script>

<style lang="scss">
  @import "@/assets/scss/global.scss";
  $multipleCount: 1;

  .container {
    width: 100%;
    height: 100vh;
    padding: px2rem(12);
    box-sizing: border-box;

    .title {
      font-weight: bold;
      font-size: px2rem(12 * $multipleCount);
    }

    .textarea {
      width: 520px; 
    }

    .qrcode {
      width: px2rem(60 * $multipleCount);
      height: px2rem(60 * $multipleCount);
      background-color: #fff;
    }
  }
</style>
